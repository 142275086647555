<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Información del folio" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: false, text: nameDocumentsType($route.params.id) }" :dense="true" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn class="mr-2" v-if="$store.state.auth.account.auto_caf" @click="dialogRequestFolio=true" outlined color="secondary"><v-icon left>mdi-hand-pointing-up</v-icon>Solicitar folios</v-btn>
        <v-btn class="mr-2" v-if="$store.state.auth.account.auto_caf" @click="dialogConfFolio=true" outlined color="secondary"><v-icon left>mdi-cog</v-icon>Configuración</v-btn>
        <v-query-builder :query-map="filters" model="folios" />
      </template>
    </mini-header>
    <!-- end header -->
    <v-col cols="12" class="px-0">
      <skeleton-list v-if="loading" />
      <template v-else>
        <v-empty-state v-if="!cafsList.length" title="No existen folios para mostrar" type="documents" id="folios" :isFree="true" customClass="mt-2" />
        <template v-else>
          <v-data-table :class="{'fixed-table' : $store.state.base.isExpandNavigationDrawer}" :headers="headers" :items="cafsList" hide-default-header disable-sort mobile-breakpoint hide-default-footer fixed-header :height="heightTable">
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders :headers="headers" />
              <v-divider style="position: absolute; z-index: 2;  margin-top: -6px; margin-left: -5px; min-width: 100%" />
            </template>

            <template v-slot:[`item.created`]="{item}">
              <span class="body-1">{{item.created | date}}</span>
            </template>
            <template v-slot:[`item.life_from`]="{item}">
              <span class="body-1">{{item.life_from | date}} - {{item.life_to | date}}</span>
            </template>
            <template v-slot:[`item.total`]="{item}">
              <span class="body-1 mr-n1">{{item.total | number}}</span>
            </template>
            <template v-slot:[`item.from_number`]="{item}">
              <span class="body-1 mr-n1">{{item.from_number}} - {{item.to_number}}</span>
            </template>
            <template v-slot:[`item.available_to_give`]="{item}">
              <v-icon v-if="!item.available_to_give" color="success">mdi-check-circle-outline</v-icon>
              <v-icon v-else x-small color="secondary">mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.available_to`]="{item}">
              <v-icon v-if="item.available_to_give" color="success">mdi-check-circle-outline</v-icon>
              <v-icon v-else color="secondary">mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.auto`]="{item}">
              <v-icon v-if="item.auto" color="primary">mdi-refresh-auto</v-icon>
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-n4" v-bind="attrs" v-on="on" text @click="current = item" x-small><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item class="px-3" @click="download(item.data)" :ripple="false">
                    <v-list-item-title class="body-2"><v-icon color="secondary" size="20" left>mdi-code-braces-box</v-icon>Descargar XML</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
        </v-data-table>
        <VMainPagination :count="count" />
        </template>
      </template>
    </v-col>

    <!-- dialog conf folio -->
    <v-dialog v-model="dialogConfFolio" width="932" persistent no-click-animation :retain-focus="false" scrollable overlay-color="overlay">
      <v-card flat :key="dialogConfFolio===false">
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-6 subtitle-2 fontBody--secondary font-weight-semibold">
              Configuración de folios para {{ nameDocumentsType($route.params.id).toLowerCase() }}
              <v-btn class="ml-1" @click="dialogConfFolio=false, panel=true" color="primary" text>¿Qué es esto?</v-btn>
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="handlerCloseConfigDialog" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-5 background">
          <!-- charging auto-->
          <v-card flat>
            <v-card-text class="pa-0">
              <v-list class="px-5 transparent v-form-list" max-width="100%">
                <v-list-item>
                  <v-list-item-title class="body-1 fontBody--text font-weight-bold d-flex align-center">
                    <v-icon color="secondary" size="18" left>mdi-refresh-auto</v-icon>
                    Autorización y carga automática de folios
                  </v-list-item-title>
                  <v-list-item-action class="ma-0">
                    <v-switch class="ma-0" v-model="showAuto" color="success" hide-details :ripple="false "/>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <template v-if="showAuto">
                <v-divider />
                <v-row class="pa-5" align="center" no-gutters>
                  <span class="body-1 fontBody--text">Cuando el número de folios restantes sea</span>
                  <v-col cols="2" class="pa-0 mx-3">
                    <v-text-field v-model="$v.configFolio.min_available.$model" :error="$v.configFolio.min_available.$error" :loading="loadingDetailConfig" oninput="this.value=this.value.replace(/[^0-9]/g,'');" height="26" outlined single-line solo flat dense hide-details />
                  </v-col>
                  <span class="body-1 fontBody--text">se deben solicitar</span>
                  <v-col cols="2" class="pa-0 mx-3">
                    <v-text-field v-model="$v.configFolio.quantity.$model" :error="$v.configFolio.quantity.$error" :loading="loadingDetailConfig" oninput="this.value=this.value.replace(/[^0-9]/g,'');" height="26" outlined single-line solo flat dense hide-details />
                  </v-col>
                  <span class="body-1 fontBody--text">folios.</span>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
          <!-- end charging auto -->

          <!-- notification-->
          <v-card flat class="mt-5">
            <v-card-title class="pa-0">
              <v-toolbar flat height="46" class="transparent">
                <v-toolbar-title class="px-4 subtitle-2 fontBody--secondary font-weight-semibold">
                  <v-icon color="secondary" size="18" left>mdi-bell</v-icon>Configuración de folios
                </v-toolbar-title>
              </v-toolbar>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <v-list class="transparent v-form-list" max-width="100%">
                <v-list-item class="px-5">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 fontBody--text font-weight-bold d-flex align-center">Disponibilidad de folios</v-list-item-title>
                    <v-list-item-subtitle class="body-1 mt-1">Recibe notificaciones sobre la cantidad restante de folios</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="ma-0">
                    <v-switch class="ma-0" v-model="notification[0].isActiveCafNotification" color="success" hide-details />
                  </v-list-item-action>
                </v-list-item>
                <v-col class="py-0 px-5">
                  <v-col class="pt-0 pb-2 px-1" v-if="loadingNotification">
                    <v-skeleton-loader class="rounded-md" v-bind="attrs" type="image" height="30" />
                  </v-col>
                  <template v-else-if="notification[0].isActiveCafNotification">
                    <span class="body-1 fontBody--text d-flex align-center pb-2">Cantidad restante
                      <v-col cols="2" class="pa-0 mx-3">
                        <v-text-field v-model="notification[0].remainingQuantity" oninput="this.value=this.value.replace(/[^0-9]/g,'');" placeholder="--" height="26" outlined single-line solo flat dense hide-details />
                      </v-col>
                    </span>
                  </template>
                </v-col>
                <v-divider class="my-1"/>
                <v-list-item class="px-5">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 fontBody--text font-weight-bold d-flex align-center">Vencimiento de folios</v-list-item-title>
                    <v-list-item-subtitle class="body-1 mt-1">Recibe notificaciones sobre el vencimiento de los folios</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="ma-0">
                    <v-switch class="ma-0" v-model="notification[1].isActiveDueNotification" color="success" hide-details />
                  </v-list-item-action>
                </v-list-item>
                <v-col class="py-0 px-4">
                  <v-col class="pt-0 pb-2 px-1" v-if="loadingNotification">
                    <v-skeleton-loader class="rounded-md" v-bind="attrs" type="image" height="120" />
                  </v-col>
                  <v-col cols="12" class="pa-0 pb-3 body-1 fontBody--text d-block" v-else-if="notification[1].isActiveDueNotification">
                    <v-col cols="12" class="pa-0 d-block" v-for="(day, d) in daysList" :key="d" style="min-width: 90px !important">
                      <v-checkbox class="ma-0 vcheckbox" v-model="notification[1].expirationSheets" :value="day" row :label="`${day} día${day > 1 ? 's' : ''} antes del vencimiento`" dense hide-details />
                    </v-col>
                  </v-col>
                </v-col>
              </v-list>
            </v-card-text>
          </v-card>
          <!-- end notification -->

          <!-- recipients-->
          <v-card flat class="mt-5" v-if="$helpers.hasPermission(['view_member'])">
            <v-card-title class="pa-0">
              <v-toolbar flat height="46" class="transparent">
                <v-toolbar-title class="px-4 subtitle-2 fontBody--secondary font-weight-semibold">
                  <v-icon color="secondary" size="18" left>mdi-account-multiple</v-icon>Destinatarios
                </v-toolbar-title>
              </v-toolbar>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0 pb-4">
              <v-list-item class="px-5 my-0" three-line>
                <v-list-item-content class="py-0">
                  <v-list-item-subtitle class="body-1">Selecciona a qué usuarios se les enviarán notificaciones sobre los folios de {{configFolio?.document_type?.name}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-col class="px-5 mt-n7" cols="8">
                <v-autocomplete
                  v-model="configFolio.mails"
                  :error="$v.configFolio.mails.$error"
                  hide-details
                  single-line
                  color="primary"
                  dense
                  outlined
                  :items="membersList"
                  multiple
                  :chips="false"
                  item-text="email"
                  item-value="email"
                  return-object
                  :label="(configFolio.mails || []).length ? '' : 'Selecciona a los usuarios'"
                  :prefix="(configFolio.mails || []).length >= 1 ? `${(configFolio.mails || []).length === 1 ? `${(configFolio.mails || []).length} seleccionado` : `${(configFolio.mails || []).length} seleccionados`}` : ''"
                  no-data-text="No existen usuarios para mostrar"
                >
                  <template v-slot:selection="{ props }">
                    <span v-if="props" style=""></span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-row no-gutters>
                <v-col class="px-5">
                  <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(m, i) in configFolio.mails" :key="`${m}${i}`" close @click:close="memberRemove(m, 0)" color="lightBlue" small label close-icon="mdi-close">
                    {{m.email || m}}
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- end recipients -->
        </v-card-text>
        <v-divider />
        <v-card-actions class="text-right py-2 px-5">
          <v-spacer />
          <v-btn @click="handlerCloseConfigDialog" outlined color="secondary">Cancelar</v-btn>
          <v-btn @click="updateConfig" :loading="loadingConfig" color="primary">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog conf folio -->

    <!-- dialog request folio -->
    <v-dialog v-model="dialogRequestFolio" width="870" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card flat>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Solicitar folios</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="handlerCloseRequestFolio" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-1 pb-4 px-0">
          <v-row class="px-5" align="center" no-gutters>
            <v-col cols="4" class="mt-7">
              <img :src="require(`@/assets/backgrounds/modal-folios-upload.svg`)" :width="250" />
            </v-col>
            <v-col class="pl-2 pr-1">
              <div class="mt-5">
                <span class="d-block mt-3 body-1 fontBody--text font-weight-bold mb-2">Cantidad de folios</span>
                <v-col cols="2" class="pa-0 mt-3">
                  <v-text-field v-model="newRequestFolio.quantity" :error="$v.newRequestFolio.quantity.$error" oninput="this.value=this.value.replace(/[^0-9]/g,'');" height="26" outlined single-line solo flat dense hide-details />
                </v-col>
                <span class="d-block mt-4 body-1 fontBody--text font-weight-bold">Uso de folios</span>
                <span class="body-2 mt-1 d-block">Selecciona el esquema de emisión bajo el cual se utilizarán estos folios.</span>
                <v-item-group :key="reset">
                  <v-container class="px-0">
                    <v-row>
                      <v-col cols="6" clas="pa-0">
                        <v-item v-slot="{ active, toggle }">
                          <v-card
                            height="90"
                            @click="toggle"
                            outlined
                            flat
                            rounded="sm"
                            :ripple="false"
                            disabled
                            :style="`border: 1px solid var(${active ? '--light-blue-primary' : '--light-blue-grey'})`"
                          >
                          <v-card-title class="pa-0">
                            <v-toolbar flat height="18" class="transparent">
                              <v-spacer />
                              <v-toolbar-items>
                                <v-row align="center" justify="center" class="mr-2 pt-3">
                                  <v-tooltip color="bgSearch" right transition="scale-transition" :max-width="150">
                                    <template v-slot:activator="{on}">
                                      <v-icon v-on="on" small color="secondary">mdi-information</v-icon>
                                    </template>
                                    <span class="d-block px-3 py-2">El uso estará destinado a la emisión de documentos a través de agente y SOAP.</span>
                                  </v-tooltip>
                                </v-row>
                              </v-toolbar-items>
                            </v-toolbar>
                          </v-card-title>
                          <v-card-text class="pa-0 d-flex align-center">
                            <div class="flex-grow-1 text-center">
                              <v-icon color="primary" size="25">mdi-server-network</v-icon>
                              <span class="text-body-2 fontDraw--text d-block mt-3">Emisión vía agente o webservice</span>
                            </div>
                          </v-card-text>
                          </v-card>
                        </v-item>
                      </v-col>
                      <v-col cols="6" clas="pa-0">
                        <v-item v-slot="{ active, toggle }">
                          <v-card
                            height="90"
                            @click="toggle"
                            outlined
                            flat
                            rounded="sm"
                            :ripple="false"
                            disabled
                            :style="`border: 1px solid var(${!active ? '--light-blue-primary' : '--light-blue-grey'})`"
                          >
                            <v-card-title class="pa-0">
                              <v-toolbar flat height="18" class="transparent">
                                <v-spacer />
                                <v-toolbar-items>
                                  <v-row align="center" justify="center" class="mr-2 pt-3">
                                    <v-tooltip color="bgSearch" left transition="scale-transition" :max-width="150">
                                      <template v-slot:activator="{on}">
                                        <v-icon v-on="on" small color="secondary">mdi-information</v-icon>
                                      </template>
                                      <span class="d-block px-3 py-2">El uso estará destinado a la emisión de documentos a través de API-REST y facturador manual.</span>
                                    </v-tooltip>
                                  </v-row>
                                </v-toolbar-items>
                              </v-toolbar>
                            </v-card-title>
                            <v-card-text class="pa-0 d-flex align-center">
                              <div class="flex-grow-1 text-center">
                                <v-icon color="primary" size="25">mdi-monitor-dashboard</v-icon>
                                <span class="text-body-2 fontDraw--text d-block mt-3">Emisión vía plataforma</span>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-item-group>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="text-right py-2 px-5">
          <v-spacer />
          <v-btn @click="handlerCloseRequestFolio" outlined color="secondary">Cancelar</v-btn>
          <v-btn @click="createRequestCaf" :loading="loadingRequestFolio" :disabled="!newRequestFolio?.quantity?.length" color="primary">Solicitar folios</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog request folio -->

    <!-- panel inf -->
    <VPanel :active="panel" title="Ayuda" @close="panel=false">
      <div class="lightGrey px-5 py-6">
        <v-card flat style="min-height: calc(100vh - 92px) !important">
          <v-card-text class="mt-11 px-5" style="min-height: calc(100vh - 150px) !important">
            <span class="d-block mb-5 mt-2 body-1 fontBody--text font-weight-semibold">Configuración de folios</span>
            <p class="fontBody--text">
              Facilita la automatización de la solicitud de folios al Sistema de Impuestos Internos. En consecuencia, ya no será necesario que estés pendiente de cargar en el sistema de facturación los CAF cuando estos se agoten.
              Recuerda tener en cuenta lo siguiente
            </p>
            <p class="fontBody--text mx-4">
              <ol>
                <li class="font-weight-bold">
                  Cantidad mínima de folios disponibles:<span class="font-weight-regular"> Define un umbral mínimo de folios que activará automáticamente la solicitud, evitando interrupciones en la emisión de documentos tributarios.</span>
                </li>
                <li class="my-3 font-weight-bold">
                  Cantidad de folios que debe contener el nuevo CAF:<span class="font-weight-regular"> Verifica y especifica la cantidad exacta de folios asignados a cada nuevo CAF generado. Valida en el Sistema de Impuestos Internos la cantidad autorizada para garantizar una gestión eficiente de los recursos.</span>
                </li>
                <li class="font-weight-bold">
                  Correos electrónicos a los que se notificará la carga automática: <span class="font-weight-regular">Selecciona las direcciones de correo electrónico para recibir notificaciones automáticas una vez que la carga automática de folios se haya completado con éxito. </span>
                </li>
              </ol>
            </p>
            <p class="fontBody--text">
              Estamos comprometidos a mejorar la eficiencia en el manejo de los folios, y estas indicaciones contribuirán a optimizar el proceso de manera efectiva.
            </p>
          </v-card-text>
          <v-card-actions class="px-5 pb-0">
            <v-btn :href="`https://docs.axteroid.com/`" target="_blank" class="mb-4" :ripple="false" text>
              <span class="d-flex">
                <img :src="require(`@/assets/navigationdrawer-ax/help-icon${$vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="18" />
                <span class="ml-2 d-inline-block secondary--text subtitle-2 font-weight-medium text-truncate">Centro de Ayuda</span>
              </span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </VPanel>
    <!-- panel inf -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { saveAs } from 'file-saver'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import MiniHeader from '@/components/commons/MiniHeader'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ListViewMixin from '@/mixins/ListViewMixin'
import documentTypesList from '@/collections/documentTypes'
import VEmptyState from '@/components/commons/VEmptyState'
import VMainPagination from '@/components/commons/VMainPagination'
import VPanel from '@/components/commons/VPanel'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    VEmptyState,
    MiniHeader,
    VMainPagination,
    SkeletonList,
    VPanel,
    VQueryBuilder: () => import('@/components/VQueryBuilder/VQueryBuilder'),
    VTableHeaders: () => import('@/components/VTable/VTableHeaders')
  },
  mixins: [
    GenericViewMixin, ListViewMixin
  ],
  data: () => ({
    reset: 0,
    attrs: {
      boilerplate: true
    },
    loadingNotification: false,
    daysList: [30, 10, 5, 2, 1],
    notification: [
      {
        remainingQuantity: null,
        model: 'number',
        rule: 'menor;cantidad;',
        isActiveCafNotification: false,
        backup: {}
      },
      {
        expirationSheets: [],
        model: 'caf',
        rule: 'igual;dias;',
        isActiveDueNotification: false,
        backup: {}
      }
    ],
    panel: false,
    confirm: '',
    newRequestFolio: {
      document_type: '',
      quantity: '',
      test_mode: false
    },
    foundConfig: {},
    dialogRequestFolio: false,
    loadingRequestFolio: false,
    loadingConfig: false,
    loadingDetailConfig: false,
    showAuto: false,
    configFolio: {
      document_type: '',
      min_available: null,
      quantity: null,
      mails: []
    },
    dialogConfFolio: false,
    scroll: 0,
    loading: false,
    documentTypes: documentTypesList.filter(d => d.country === 'CL'),
    headers: [
      { text: 'Fecha', value: 'created', sortable: true, width: 50 },
      // { text: 'Usuario', value: 'email' },
      { text: 'Vigencia', value: 'life_from', align: 'end', sortable: true },
      { text: 'Rango', value: 'from_number', align: 'end' },
      { text: 'Total', value: 'total', align: 'end' },
      { text: 'WS/Agente', value: 'available_to_give', align: 'center' },
      { text: 'Plataforma', value: 'available_to', align: 'center' },
      { text: ' ', value: 'auto', align: 'center' },
      { text: '', value: 'actions', align: 'end', width: 30 }
    ],
    filters: [
    {
        type: 'date',
        id: 'created',
        label: 'Fecha de creación',
        value: null,
        value2: null,
        category: 'a',
        operator: null,
        operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
      },
      {
        type: 'date',
        id: 'life',
        label: 'Fecha de vigencia',
        value: null,
        value2: null,
        category: 'a',
        operator: null,
        operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
      },
      {
        type: 'select',
        id: 'available_to_give',
        label: 'Uso del folio',
        value: null,
        category: 'b',
        choices: [
          {label: 'WS/Agente', value: 'False'},
          {label: 'Plataforma', value: 'True'}
        ]
      }
    ],
    breadcrumbs: {
      main: 'Folios',
      children: []
    },
    env: process.env?.VUE_APP_MODE ?? 'ax',
    isSaveConfig: false,
    isSaveNotification: false,
    isErrorConfig: false,
    confList: []
  }),
  computed: {
    heightTable () {
      if (this.$vuetify.breakpoint.height <= 1200 && this.cafsList.length >= 16 && this.cafsList.length <= 20) return 'calc(100vh - 210px)'
      if (this.cafsList.length > 20) return 'calc(100vh - 165px)'
      if (this.cafsList.length < 16) return 'auto'
      return 'calc(100vh - 275px)'
    },
    ...mapState({
      cafsList: state => state.irs.cafsList,
      count: state => state.irs.cafsCount,
      membersList: state => state.accounts.membersList
    })
  },
  mounted () {
    if (['true', true].includes(this.$route.query.isAdmin)) this.dialogConfFolio = true
  },
  created () {
    this.getList()
    if (this.$helpers.hasPermission(['view_member'])) {
      this.$store.dispatch('accounts/LIST', {
        resource: 'members',
        query: {
          page_size: 9999,
          is_active: true
        }
      })
    }
  },
  watch: {
    showAuto (val) {
      if (!val) {
        this.$v.configFolio.$reset()
      }
    },
    dialogConfFolio (val) {
      this.showAuto = false
      if (val) {
        this.notification = [
          {
            remainingQuantity: null,
            model: 'number',
            rule: 'menor;cantidad;',
            isActiveCafNotification: false,
            backup: {}
          },
          {
            expirationSheets: [],
            model: 'caf',
            rule: 'igual;dias;',
            isActiveDueNotification: false,
            backup: {}
          }
        ]
        this.loadingDetailConfig = true
        this.loadingNotification = true
        this.$store.dispatch('irs/LIST', {
          resource: 'irs/sii/config_auto_caf',
          query: { document_type: this.$route.params.id }
        })
        .then((response) => {
          this.confList = response.data.results
          this.foundConfig = this.confList.find((item) => item.document_type === this.$route.params.id)
          if (this.foundConfig !== undefined) {
            this.$store.dispatch('irs/RETRIEVE', {
              resource: 'irs/sii/config_auto_caf',
              id: response.data.results[0].id
            })
            .then((response) => {
              this.configFolio = {...response.data}
              if (this.configFolio.quantity >= 0 || this.configFolio.min_available >= 0) this.showAuto = true
            })
          }
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
        .finally(() => {
          this.loadingDetailConfig = false
          this.loadingNotification = false
        })

        this.$store.dispatch('accounts/LIST', {
          resource: 'notifications'
        })
        .then((resp) => {
          this.configFolio.mails = !this.configFolio.mails.length ? resp.data.results?.find((item) => item.message === this.$route.params.id).members : this.configFolio.mails
          const number = resp.data.results?.find((item) => item.model === 'number' && item.message === this.$route.params.id) ?? {}
          const caf = resp.data.results?.find((item) => item.model === 'caf' && item.message === this.$route.params.id) ?? {}
          const numberValue = number?.rule ? number?.rule?.match(/menor;cantidad;(\d+)/) : 0
          const cafValue = caf?.rule ? caf.rule?.match(/igual;dias;\[(.*?)\]/) ?? '' : []
          this.notification[0].backup = number
          this.notification[0].isActiveCafNotification = number.is_active
          this.notification[0].remainingQuantity = numberValue ? numberValue[1] : null
          this.notification[1].backup = caf
          this.notification[1].isActiveDueNotification = caf.is_active
          this.notification[1].expirationSheets = cafValue ? cafValue[1]?.split(',')?.map((item) => parseInt(item)) ?? '' : []
        })
      }
    }
  },
  methods: {
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    getList () {
      this.loading = true
      let query = JSON.parse(JSON.stringify(this.$route.query))
      if (query?.isAdmin) delete query.isAdmin
      this.$store.dispatch('irs/LIST', {
        resource: 'irs/sii/cafs',
        query: {
          ...query,
          document_type: this.$route.params.id
        }
      })
      .then(() => {
        // this.setFilterCaf()
      })
      .finally(() => {
        this.loading = false
      })
    },
    setFilterCaf () {
      if (this.cafsList.length) {
        const index = this.filters.findIndex(({id}) => id === 'number')
        /* eslint-disable */
        this.filters[index].choices = this.cafsList.map(({from_number, to_number}) => {
          return {
            label: `${from_number} - ${to_number}`,
            value: `${from_number}-${to_number}`
          }
        })
      }
    },
    download (data = null) {
      if (data) {
        const content = Buffer.from(data, 'base64')
        saveAs(new Blob([content], {type: 'application/xml'}), 'Detalle stock de folios.xml')
        this.$dialog.message.info('La exportación se realizó con éxito')
      } else {
        this.$dialog.message.error('Ha ocurrido un error al descargar el xml')
      }
    },
    memberRemove (item) {
      this.configFolio.mails = [...this.configFolio.mails.filter((m) => m !== item)]
    },
    async updateConfig () {
      if (this.showAuto) {
        this.$v.configFolio.$touch()
        if (this.$v.configFolio.$invalid) {
          return false
        }

        this.isErrorConfig = false
        this.isSaveNotification = false
        this.isSaveConfig = false
        this.loadingConfig = true

        const payload = {
          min_available: this.configFolio.min_available ?? 0,
          quantity: this.configFolio.quantity ?? 0,
          document_type: this.$route.params.id,
          mails: this.configFolio.mails.map((m) => m?.email ?? m)
        }

        this.$store.dispatch(`irs/${this.foundConfig ? 'UPDATE' : 'CREATE'}`, {
          resource: 'irs/sii/config_auto_caf',
          ...(this.foundConfig && { id: this.foundConfig.id}),
          payload: payload
        })
        .then(() => {
          this.isSaveConfig = true
        })
        .catch((error) => {
          this.isErrorConfig = true
        })
      }
      let notificationPayload = {}
      const members = this.configFolio.mails.map((elm) => {
        return {
          member: this.membersList.find(({email}) => (elm?.email ?? elm) === email).id
        }
      })
      await Promise.all(this.notification.map((item) => {
        notificationPayload = {
          is_active: (item.isActiveCafNotification || item.isActiveDueNotification) ?? false,
          members: members,
          rule: `${item.rule}${item.model === 'number' ? item.remainingQuantity ?? 0 : `[${item?.expirationSheets?.length ? item.expirationSheets.toString() : ''}]`}`,
          model: item.model,
          kind: 'email',
          message: this.$route.params.id
        }
        if (item.backup.id) {
          this.$store.dispatch('accounts/UPDATE', {
            resource: 'notifications',
            payload: notificationPayload,
            id: item.backup.id
          })
        } else {
          this.$store.dispatch('accounts/CREATE', {
            resource: 'notifications',
            payload: notificationPayload
          })
        }
      }))
      this.isSaveNotification = true

      if (this.isSaveConfig || this.isSaveNotification) {
        this.$dialog.message.info('Configuración guardada correctamente')
        this.loadingConfig = false
        this.notification = [
          {
            remainingQuantity: null,
            model: 'number',
            rule: 'menor;cantidad;',
            isActiveCafNotification: false,
            backup: {}
          },
          {
            expirationSheets: [],
            model: 'caf',
            rule: 'igual;dias;',
            isActiveDueNotification: false,
            backup: {}
          }
        ]
        this.handlerCloseConfigDialog()
      } else if (this.isErrorConfig) this.$dialog.message.error(this.parseErrors(error.response.data))
    },
    handlerCloseConfigDialog () {
      this.$router.replace({ query: {}}).catch(() => {})
      this.dialogConfFolio = false
      this.configFolio = {
        min_available: null,
        quantity: null,
        document_type: '',
        mails: []
      }
      this.$v.configFolio.$reset()
    },
    createRequestCaf () {
      this.$v.newRequestFolio.$touch()
      if (this.$v.newRequestFolio.$invalid) {
        return false
      }

      this.loadingRequestFolio = true

      const payload = {
        test_mode: false,
        quantity: this.newRequestFolio.quantity,
        document_type: this.$route.params.id,
      }

      this.$store.dispatch('irs/CREATE', {
        resource: 'irs/sii/request_auto_caf/manual',
        payload: payload
      })
      .then(() => {
        this.$dialog.message.info('Solicitud procesada correctamente')
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.loadingRequestFolio = false
        this.handlerCloseRequestFolio()
      })
    },
    handlerCloseRequestFolio () {
      this.confirm = ''
      this.dialogRequestFolio = false
      this.newRequestFolio = {
        quantity: null,
        document_type: '',
        test_mode: false
      }
      this.$v.newRequestFolio.$reset()
    }
  },
  validations: {
    configFolio: {
      mails: {
        required
      },
      quantity: {
        required
      },
      min_available: {
        required
      }
    },
    newRequestFolio: {
      quantity: {
        required
      }
    }
  }
}
</script>